<template>
  <v-container fluid>
    <v-card flat :height="headerCardHeight">
      <v-row no-gutters class="fill-height">
        <v-col cols="3" class="mt-8">
          <v-card-title class="text-h4">
            <v-icon large left color="primary lighten-3">
              mdi-google-analytics
            </v-icon>
            Kiosk Analytics
            <v-menu bottom max-height="400">
              <template v-slot:activator="{ on }">
                <v-chip class="text-h6 mt-3" outlined :color="getColor(null)" v-on="on" elevation="2">
                  <v-icon left class="mr-1">mdi-circle</v-icon>
                  {{ getKioskName }}
                  <v-icon right>mdi-chevron-down</v-icon>
                </v-chip>
              </template>
              <v-list>
                <v-list-item-group>
                  <v-list-item v-for="(item, i) in allRegisteredKiosks" :key="i">
                    <v-list-item-icon><v-icon
                        :color="getColor(item)">mdi-checkbox-blank-circle</v-icon></v-list-item-icon>
                    <v-list-item-title @click="getAnalytics(item)" class="my-2">
                      {{ item.data().name }}
                    </v-list-item-title>
                    <v-divider></v-divider>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-card-title>
        </v-col>
        <v-col cols="9">
          <v-row no-gutters class="mt-8">
            <v-col cols="4">
              <v-card width="85%">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total User Sessions
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="kioskAnalytics.totalUserSessions > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ kioskAnalytics.totalUserSessions }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card width="85%">
                <v-card-title class="justify-center">
                  <span class="primary--text text--lighten-1 font-weight-regular">
                    Total Questions Asked
                  </span>
                </v-card-title>
                <v-card-text class="d-flex justify-center">
                  <span v-if="analysisList.length > 0"
                    class="text-h5 font-weight-bold grey--text text--darken-2">
                    {{ analysisList.length }}
                  </span>
                  <span class="text-h5 font-weight-bold grey--text text--darken-2" v-else>
                    NA
                  </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-card class="px-3 mt-3" flat>
      <v-tabs v-model="tab">
        <v-tab>
          <v-icon left>
            mdi-google-analytics
          </v-icon>
          Graphs</v-tab>
        <v-tab>
          <v-icon left>
            mdi-timer-check
          </v-icon>
          User Sessions</v-tab>
        <v-tab>
          <v-icon left>
            mdi-forum
          </v-icon>
          Q&A Analytics</v-tab>
        <v-spacer></v-spacer>
        <div class="mt-4 mx-4 font-weight-bold">
          {{ displayDate }}
        </div>
        <v-card flat width="200">
          <v-select :items="duration" outlined v-model="selectedDuration" dense class="mt-2" ref="durationSelector">
            <template v-slot:item="data">
              <v-list-item v-if="data.item === 'Select Date'"
                @click="$store.commit('setSingleDatePicker', true); selectedDuration = 'Select Date'">
                Select Date
              </v-list-item>
              <v-list-item v-else-if="data.item === 'Select Week'"
                @click="$store.commit('setWeekDatePicker', true); selectedDuration = 'Select Week'">
                Select Week
              </v-list-item>
              <v-list-item v-else @click="durationSetter(data.item)">{{ data.item }}</v-list-item>
            </template>
          </v-select>
        </v-card>
        <!-- <v-icon class="mt-3 mx-2">mdi-reload</v-icon> -->
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <reports :userSessionList="userSessionList" :selectedDuration="selectedDuration"></reports>
        </v-tab-item>
        <v-tab-item>
          <userSession :loading="loading" :userSessionList="userSessionList">
          </userSession>
        </v-tab-item>
        <v-tab-item>
          <qnaAnalytics :analysisList="analysisList" :loading="loading">
          </qnaAnalytics>
        </v-tab-item>

        <!-- <v-tab-item>
                <reports></reports>                
              </v-tab-item> -->
      </v-tabs-items>
      <v-card-title class="pa-0 text-caption d-flex align-end justify-end">
        <span>Last Synced: {{ getCurrentDate }} | <a @click="syncData()">Sync Data</a></span>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import userSession from "@/components/dashboard/admin/kioskAnalytics/userSession.vue";
import qnaAnalytics from "@/components/dashboard/admin/kioskAnalytics/qnaAnalytics.vue";
import Reports from '../../components/dashboard/admin/kioskAnalytics/reports.vue';
import { color } from "d3";

export default {
  name: "kioskAnalytics",
  components: {
    userSession,
    qnaAnalytics,
    Reports
  },
  data() {
    return {
      selectedKiosk: null,
      tab: null,
      loading: false,
      headerCardHeight: window.innerHeight / 5.5,
      duration: ['Today', 'Yesterday', 'This Week', '2024', 'Select Date', 'Select Week', 'Fiscal Year', 'All'],
      selectedDuration: 'Today',
      date: new Date(),
      count: 0,
      totalQuestionsAsked: 0
    };
  },
  computed: {
    ...mapGetters([
      "allRegisteredKiosks",
      "kioskAnalytics",
      "getWindowDimensions",
      "selectKioskAnalytics",
      "displayDate"
    ]),
    getCurrentDate() {
      return moment(this.date).format('lll')
    },
    kioskList() {
      let kioskName = [];
      this.allRegisteredKiosks.forEach((kiosk) =>
        kioskName.push(kiosk.data().name)
      );
      return kioskName;
    },
    getKioskName() {
      let temp;
      console.log('Selected Kiosk: ', this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationCode === this.selectKioskAnalytics))
      temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationCode === this.selectKioskAnalytics)
      return temp[0].data().name
    },
    analysisList() {
      let interactions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        let qnaInteraction = event.sessionData.filter(session => session.actionType === 'Speech')
        qnaInteraction.forEach(qnaData => {
          console.log('QNA data: ', qnaData)
          interactions.push({
            kioskId: qnaData.applicationId,
            result: qnaData.response,
            timeStamp: event.startDate,
            transcript: qnaData.transcript.trim(),
            id: this.count++
          });
        })
      });
      return interactions
      //return interactions.filter(interaction => interaction.kioskId === this.selectKioskAnalytics);
    },
    userSessionList() {
      let sessions = [];
      this.kioskAnalytics.userSessionAnalytics.forEach((event) => {
        console.log('Making of user Session List: ', event)
        let startTime = moment(event.startDate);
        let endTime = moment(event.endDate);
        sessions.push({
          id: event.applicationSessionId,
          kioskId: event.applicationId,
          userSessionDate: event.startDate,
          userSessionDuration: endTime.diff(startTime),
          userSessionStartTime: event.startDate,
          userSessionEndTime: event.endDate,
          userSessionLogs: event.sessionData,
        });
      });
      console.log('Sessions : ', sessions)
      return sessions.filter(session => session.kioskId === this.selectKioskAnalytics);
      //return sessions
    },
  },
  watch: {
    selectedKiosk(newVal) {
      this.loadData(newVal);
    },
    getWindowDimensions(newVal) {
      this.headerCardHeight = newVal.height / 5.5;
    },
    selectedDuration(newVal) {
      if (newVal === 'Today') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else if (newVal === 'Yesterday') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Yesterday',
          start: null,
          end: null
        })
      } else if (newVal === 'This Week') {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'This Week',
          start: null,
          end: null
        })
      } else if (newVal === '2024') {
        this.$store.dispatch('getKioskAnalytics', {
          type: '2024',
          start: null,
          end: null
        })
      } else if (newVal === 'Fiscal Year') {
        this.$store.commit("setFiscalYearPicker", true)
      } else if (newVal === null) {
        this.$store.dispatch('getKioskAnalytics', {
          type: 'Today',
          start: null,
          end: null
        })
      } else {
        //Custom Date
      }
    }
  },
  methods: {
    syncData() {
      console.log('Current Selection: ', this.selectedDuration)
      this.date = new Date()
      this.$store.dispatch('getKioskAnalytics', {
        type: this.selectedDuration,
        start: null,
        end: null
      })
    },
    getAnalytics(item) {
      this.$store.commit("setSelectKioskAnalytics", item.data().applicationId)
      this.$store.dispatch('getKioskAnalytics', {
        type: 'Today',
        start: null,
        end: null
      })
      this.tab = 0
    },
    durationSetter(timeframe) {
      this.selectedDuration = timeframe
      this.$refs.durationSelector.isMenuActive = false;
    },
    getColor(kiosk) {
      if (kiosk === null) {
        let temp;
        console.log('Selected Kiosk: ', this.selectKioskAnalytics)
        temp = this.allRegisteredKiosks.filter(kiosk => kiosk.data().applicationId === this.selectKioskAnalytics)
        console.log('Didi I find the kiosk: ', temp)
        //return temp[0].data().name
        if (temp[0].data().onlineStatus === 'Available') {
          return 'success'
        } else {
          return 'error'
        }
      } else {
        if (kiosk.data().onlineStatus === 'Available') {
          return 'success'
        } else {
          return 'error'
        }
      }

      // if (status === "Unavailable") return "error";
      // if (status === "Busy") return "amber";
      // if (status === "Available") return "success";
    }
  },
  mounted() {
    if (this.allRegisteredKiosks.length === 0) {
      this.$store.dispatch("getAllRegisteredKiosks");
    }
  },
};
</script>